import { onAuthStateChanged } from '@firebase/auth';
import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router'
import { setUser } from '../app/slices/AuthSlice';
import { firebaseAuth } from '../utils/FirebaseConfig';

function useAuth() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(()=>{
    const unsuscribe = onAuthStateChanged(firebaseAuth,(currentUser)=>{
        if (!currentUser) navigate("/login");
        else{
            dispatch(setUser({
                uid: currentUser.uid,
                name: currentUser.displayName,
                email: currentUser.email
            }));
        }
    });
    return () => unsuscribe()
  },[dispatch, navigate]);
}

export default useAuth