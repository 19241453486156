import React, { useContext, useEffect, useState } from 'react';
import { EuiGlobalToastList, EuiProvider, EuiThemeColorMode, EuiThemeProvider } from "@elastic/eui";
import "@elastic/eui/dist/eui_theme_light.css";
import "@elastic/eui/dist/eui_theme_dark.css";
import Login from "./pages/MeetingsPages/Login";
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './app/hooks';
import CreateMeeting from './pages/MeetingsPages/CreateMeeting';
import { setToasts } from './app/slices/MeetingSlice';
import LandingPage from './pages/LandingPage';
import OneOnOneMeeting from './pages/MeetingsPages/OneOnOneMeeting';
import VideoConference from './pages/MeetingsPages/VideoConference';
import MyMeetings from './pages/MeetingsPages/MyMeetings';
import JoinMeeting from './pages/MeetingsPages/JoinMeeting';
import Meetings from './pages/MeetingsPages/Meetings';
import MeetingsDashboard from './pages/MeetingsPages/MeetingsDashboard';
import ThemeSelector from './components/MeetingsComponents/ThemeSelector';
import Home from './pages/MenuPages/Home/Home';
import Single from './pages/MenuPages/SinglePage/Single';
import New from './pages/MenuPages/New/New';
import { clientInputs, userInputs, machineInputs } from './utils/formSource';
import './style/dark.scss';
import { DarkModeContext } from './app/context/darkModeContext';
import List from './pages/MenuPages/List/List';
import TextEditor from './pages/MenuPages/TextEditor/TextEditor';
import AssistanceList from './pages/MenuPages/List/AssistanceList';
import { AuthContext } from './app/context/AuthContext';

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const dispatch = useAppDispatch();
  const isDarkTheme = useAppSelector((zoom) => zoom.auth.isDarkTheme);
  const toasts = useAppSelector((zoom => zoom.meetings.toasts));
  const [theme, setTheme] = useState<EuiThemeColorMode>("dark");
  const [isInitialEffect, setIsInitialEffect] = useState(true);

  const { currentUser } = useContext(AuthContext) ;

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  return currentUser ? children : <Navigate to="/login" />;
};
  
  const removeToast = (removedToast: { id: string }) => {
    dispatch(
      setToasts(
        toasts.filter((toast: { id: string }) => toast.id !== removedToast.id)
      )
    );
  };

  useEffect(() => {
    const theme = localStorage.getItem("zoom-theme");
    if (theme) {
      setTheme(theme as EuiThemeColorMode);
    } else {
      localStorage.setItem("zoom-theme", "light");
    }
  }, []);
  
  useEffect(() => {
    if (isInitialEffect) setIsInitialEffect(false);
    else {
      window.location.reload();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkTheme]);

  const overrides = {
    colors: {
      LIGHT: { primary: "#0b5cff" },
      DARK: { primary: "#0b5cff" },
    },
  };

  return (
    <ThemeSelector>
      <EuiProvider colorMode={theme}>
        <EuiThemeProvider modify={overrides}>
          <div className={darkMode ? "app dark" : "app"}>
          <Router>
            <Routes>
              {/*  Meetings Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/createMeeting" element={<RequireAuth><CreateMeeting /></RequireAuth>} />
              <Route path="/createOneOnOne" element={<RequireAuth><OneOnOneMeeting /></RequireAuth>} />  
              <Route path="/createVideoConference" element={<RequireAuth><VideoConference /></RequireAuth>} />  
              <Route path="/myMeetings" element={<RequireAuth><MyMeetings /></RequireAuth>} /> 
              <Route path="/join/:id" element={<RequireAuth><JoinMeeting /></RequireAuth>} /> 
              <Route path="/meetings" element={<RequireAuth><Meetings /></RequireAuth>} /> 
              <Route path="/MeetingsDashboard" element={<RequireAuth><MeetingsDashboard /></RequireAuth>} /> 

              {/* Menu Routes */}
              <Route path='/home' element={<RequireAuth><Home /></RequireAuth>} />
              <Route path='/report' element={<RequireAuth><TextEditor /></RequireAuth>} />
              

              <Route path="users">
                <Route index element={<RequireAuth><List collectionName = {"users"} /></RequireAuth>} />
                <Route path=":userId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ userInputs } title={"Añadir Nuevo Usuario"} collectionName = { "users" }/></RequireAuth>} />
              </Route>
              <Route path="clients">
                <Route index element={<RequireAuth><List collectionName = {"clients"} /></RequireAuth>} />
                <Route path=":clientId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ clientInputs } title={"Añadir Nuevo Cliente"} collectionName = { "clients" }/></RequireAuth>} />
              </Route>

              <Route path="machines">
                <Route index element={<RequireAuth><List collectionName = {"machines"} /></RequireAuth>} />
                <Route path=":machineId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ machineInputs } title={"Añadir Nueva Maquina"} collectionName = { "machines" }/></RequireAuth>} />
              </Route>

              <Route path="assistances">
                <Route index element={<RequireAuth><AssistanceList/></RequireAuth>} />
                <Route path=":assistanceId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ machineInputs } title={""} collectionName = { "assistance" }/></RequireAuth>} />
              </Route>

              <Route path="machineWiki">
                <Route index element={<RequireAuth><List collectionName={"machinesWiki"} /></RequireAuth>} />
                <Route path=":machineWId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ machineInputs } title={"Añadir Nueva Maquina"} collectionName = { "achineWiki" }/></RequireAuth>} />
              </Route>

              <Route path="technicians">
                <Route index element={<RequireAuth><List collectionName={'technicians'} /></RequireAuth>} />
                <Route path=":technicalId" element={<RequireAuth><Single /></RequireAuth>} />
                <Route path="new" element={<RequireAuth><New inputs={ userInputs } title={""} collectionName={ "technician" }/></RequireAuth>} />
              </Route>

              {/* Other Routes */}
              <Route path="/" element={<LandingPage />} />
            </Routes>
          </Router>
          </div>
          
          <EuiGlobalToastList
            toasts={toasts}
            dismissToast={removeToast}
            toastLifeTimeMs={4000}
          />
        </EuiThemeProvider>
      </EuiProvider>
    </ThemeSelector>
  );
}

export default App;