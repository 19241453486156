import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { getDocs, collection } from 'firebase/firestore';
import { getCurrentUser } from '../../../utils/getCurrentUser';
import './datatable.scss';
import {userColumns, clientColumns, machineColumns, technicianColumns, assistanceColumns } from './datatablesource';
import { firebaseDB } from '../../../utils/FirebaseConfig';

const DataTable = ({ collectionName }) => {
  const [rows, setRows] = useState([]);
  const [accessLevel, setAccessLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getCurrentUser();
        setAccessLevel(user.accessLevel);

        const querySnapshot = await getDocs(collection(firebaseDB, collectionName));
        const fetchedData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setRows(fetchedData);

        setIsLoading(false);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };

    fetchData();
  }, [collectionName]);

  const handleDelete = (id) => {
    setRows(rows.filter((item) => item.id !== id));
  };

  const actionColumn = [
    {
      field: 'action',
      headerName: '',
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.id}`} style={{ textDecoration: 'none' }}>
              <div className="viewButton">Ver</div>
            </Link>
            <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>
              Borrar
            </div>
          </div>
        );
      },
    },
  ];

  if (isLoading) {
    return <div>Cargando...</div>;
  }

  if (collectionName === 'users') {
    if (accessLevel === '0' || accessLevel === '1' || accessLevel === '2') {
      return (
        <div className="datatable">
          <div className="datatableTitle">
            Usuarios
            <Link to="/users/new" className="link">
              Añadir nuevo
            </Link>
          </div>
          <DataGrid
            className="datagrid"
            rows={rows}
            columns={userColumns.concat(actionColumn)}
            pageSize={9}
            rowsPerPageOptions={[9]}
            checkboxSelection
          />
        </div>
      );
    } else {
      return (
        <div>
          Shouldn't work
        </div>
      );
    }
  } else if (collectionName === 'clients') {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          Clientes
          <Link to="/clients/new" className="link">
            Añadir nuevo
          </Link>
        </div>
        <DataGrid
          className="datagrid"
          rows={rows}
          columns={clientColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      </div>
    );
  } else if (collectionName === 'technicians') {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          Técnicos
        </div>
        <DataGrid
          className="datagrid"
          rows={rows}
          columns={technicianColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      </div>
    );
  } else if (collectionName === 'assistance') {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          Asistencias
        </div>
        <DataGrid
          className="datagrid"
          rows={rows}
          columns={assistanceColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      </div>
    );
  } else {
    return (
      <div className="datatable">
        <div className="datatableTitle">
          Máquinas
          <Link to="/machines/new" className="link">
            Añadir nuevo
          </Link>
        </div>
        <DataGrid
          className="datagrid"
          rows={rows}
          columns={machineColumns.concat(actionColumn)}
          pageSize={9}
          rowsPerPageOptions={[9]}
          checkboxSelection
        />
      </div>
    );
  }
};

export default DataTable;
