import { EuiFlexGroup, EuiForm, EuiSpacer } from "@elastic/eui";
import { DocumentData, addDoc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useFetchUsers from "../../hooks/useFetchUser";
import useToast from "../../hooks/useToast";
import { FieldErrorType, SelectedType, UserType } from "../../utils/Types";
import { generateMeetingID } from "../../utils/GenerateMeetingId";
import { meetingsRef } from "../../utils/FirebaseConfig";
import MeetingNameField from "../../components/MeetingsComponents/FormComponents/MeetingNameField";
import MeetingUserField from "../../components/MeetingsComponents/FormComponents/MeetingUserFields";
import MeetingDateField from "../../components/MeetingsComponents/FormComponents/MeetingDateField";
import CreateMeetingButtons from "../../components/MeetingsComponents/FormComponents/CreateMeetingButton";
import Header from "../../components/MeetingsComponents/Header";
import useMachines from "../../hooks/useMachines";
import MeetingModelField from "../../components/MeetingsComponents/FormComponents/MeetingModelField";
import MeetingSerialNumberField from "../../components/MeetingsComponents/FormComponents/MeetingSerialNumberField";
import { getUserInfo } from "../../utils/getUserInfo";
import { useAppSelector } from "../../app/hooks";

export default function OneOnOneMeeting() {
  useAuth();
  const [users] = useFetchUsers();
  const machines = useMachines();
  const [createToast] = useToast();
  const userInfo = useAppSelector((zoom) => zoom.auth.userInfo)
  const [userData, setUserData] = useState<DocumentData | null>(null);

  useEffect(() => {
    const getUserData = async () => {
      if (userInfo) {
        const data = await getUserInfo(userInfo);
        setUserData(data);
      }
    };

    getUserData();
  }, [userInfo]);

  const navigate = useNavigate();

  const [meetingName, setMeetingName] = useState("");
  const [selectedUser, setSelectedUser] = useState<Array<UserType>>([]);
  const [selectedModel, setSelectedModel] = useState<Array<SelectedType>>([]);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState<Array<SelectedType>>([]);
  const [startDate, setStartDate] = useState(moment());
  const [showErrors, setShowErrors] = useState<{
    meetingName: FieldErrorType;
    meetingUser: FieldErrorType;
    meetingModel: FieldErrorType;
    meetingSerialNumber: FieldErrorType;
  }>({
    meetingName: {
      show: false,
      message: [],
    },
    meetingUser: {
      show: false,
      message: [],
    },
    meetingModel: {
      show: false,
      message: [],
    },
    meetingSerialNumber: {
      show: false,
      message: [],
    },
  });

  const onUserChange = (selectedOptions: Array<UserType>) => {
    setSelectedUser(selectedOptions);
  };

  const onModelChange = (selectedOptions: Array<SelectedType>) => {
    setSelectedModel(selectedOptions);
  };

  const onSerialNumberChange = (selectedOptions: Array<SelectedType>) => {
    setSelectedSerialNumber(selectedOptions);
  }

  const validateForm = () => {
    const showErrorsClone = { ...showErrors };
    let errors = false;
    if (!meetingName.length) {
      showErrorsClone.meetingName.show = true;
      showErrorsClone.meetingName.message = ["Por favor ingresa un nombre de reunion"];
      errors = true;
    } else {
      showErrorsClone.meetingName.show = false;
      showErrorsClone.meetingName.message = [];
    }
    if (!selectedUser.length) {
      showErrorsClone.meetingUser.show = true;
      showErrorsClone.meetingUser.message = ["Por favor selecciona un usuario"];
      errors = true;
    } else {
      showErrorsClone.meetingUser.show = false;
      showErrorsClone.meetingUser.message = [];
    }

    if (!selectedModel.length) {
      showErrorsClone.meetingModel.show = true;
      showErrorsClone.meetingModel.message = ["Por favor selecciona una maquina"];
      errors = true;
    } else {
      showErrorsClone.meetingModel.show = false;
      showErrorsClone.meetingModel.message = [];
    }

    if(!selectedSerialNumber.length) {
      showErrorsClone.meetingSerialNumber.show = true;
      showErrorsClone.meetingSerialNumber.message = ["Por favor selecciona un numero de serie"];
      errors = true;
    } else {
      showErrorsClone.meetingSerialNumber.show = false;
      showErrorsClone.meetingSerialNumber.message = [];
    }
    
    setShowErrors(showErrorsClone);
    return errors;
  };

  const createMeeting = async () => {
    if (!validateForm()) {
      const meetingId = generateMeetingID();
      await addDoc(meetingsRef, {
        createdBy: userData?.uid || '',
        meetingId,
        meetingName,
        meetingType: "1-on-1",
        invitedUsers: [selectedUser[0].uid],
        meetingDate: startDate.format("L"),
        model: selectedModel[0].label,
        serialNumber: selectedSerialNumber[0].label,
        maxUsers: 1,
        status: true,
      });
      createToast({
        title: "Reunion 1 a 1 creada con exito.",
        type: "success",
      });
      navigate("/MeetingsDashboard");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <Header />
      <EuiFlexGroup justifyContent="center" alignItems="center">
        <EuiForm>
          <MeetingNameField
            label="Nombre de la reunion"
            isInvalid={showErrors.meetingName.show}
            error={showErrors.meetingName.message}
            placeholder="Nombre de la reunion"
            value={meetingName}
            setMeetingName={setMeetingName}
          />
          <MeetingUserField
            label="Invitar usuario"
            isInvalid={showErrors.meetingUser.show}
            error={showErrors.meetingUser.message}
            options={users}
            onChange={onUserChange}
            selectedOptions={selectedUser}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Selecciona un usuario"
          />
          <MeetingModelField
            label="Machine Model"
            isInvalid={showErrors.meetingModel.show}
            error={showErrors.meetingModel.message}
            options={machines.map((machine) => { return { label: machine.model}})}
            onChange={onModelChange}
            selectedOptions={selectedModel}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Machine Model"
          />
          <MeetingSerialNumberField
            label="Machine Serial Number"
            isInvalid={showErrors.meetingSerialNumber.show}
            error={showErrors.meetingSerialNumber.message}
            options={machines.filter((machine) => machine.model === selectedModel[0]?.label).map((machine) => { return { label: machine.serialNumber}})}
            onChange={onSerialNumberChange}
            selectedOptions={selectedSerialNumber}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Machine Serial Number"
          />
          <MeetingDateField selected={startDate} setStartDate={setStartDate} />
          <EuiSpacer />
          <CreateMeetingButtons createMeeting={createMeeting} />
        </EuiForm>
      </EuiFlexGroup>
    </div>
  );
}