import { useState } from 'react'
import Sidebar from '../../../components/MenuComponents/Sidebar/Sidebar'
import Navbar from '../../../components/MenuComponents/Navbar/Navbar'
import './textEditor.scss';
import useAuth from '../../../hooks/useAuth';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";

const TextEditor = () => {
  const [value, setValue] = useState("");
  const modules = {
    toolbar: [
       [{ header: [ 1, 2, 3, 4, 5, 6, false ] }],
       [{ font: [] }],
       [{ size: [] }], 
       [ "bold", "italic", "underline", "strike", "blockquote"],
       [ {list: "ordered"}, {list: "bullet"}, {indent: "-1"}, {indent:"+1"}],
       ["link", "image", "video"]
    ]
  }

  useAuth();

  return (
    <div className='textEditor'>
        <Sidebar/>
        <div className="textEditorContainer">
            <Navbar/>
            <div className="container">
              <div className="row">
                <div className="editor">
                  <ReactQuill theme='snow' value={value} onChange={setValue} className='editor-input' modules={modules}/>
                </div>
                <div className="preview" dangerouslySetInnerHTML={{ __html: value}}/>
              </div>
            </div>
        </div>
    </div>
  )
}

export default TextEditor