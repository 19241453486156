import { EuiFlexGroup, EuiForm, EuiFormRow, EuiSpacer, EuiSwitch } from "@elastic/eui";
  import { DocumentData, addDoc } from "firebase/firestore";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useFetchUsers from "../../hooks/useFetchUser";
import useToast from "../../hooks/useToast";
import { useAppSelector } from "../../app/hooks";
import { FieldErrorType, UserType } from "../../utils/Types";
import { generateMeetingID } from "../../utils/GenerateMeetingId";
import { meetingsRef } from "../../utils/FirebaseConfig";
import MeetingNameField from "../../components/MeetingsComponents/FormComponents/MeetingNameField";
import MeetingMaximumUsersField from "../../components/MeetingsComponents/FormComponents/MeetingMaximumUsersField";
import MeetingUserField from "../../components/MeetingsComponents/FormComponents/MeetingUserFields";
import MeetingDateField from "../../components/MeetingsComponents/FormComponents/MeetingDateField";
import CreateMeetingButtons from "../../components/MeetingsComponents/FormComponents/CreateMeetingButton";
import Header from "../../components/MeetingsComponents/Header";
import { getUserInfo } from "../../utils/getUserInfo";

  export default function VideoConference() {
    useAuth();
    const [users] = useFetchUsers();
    const [createToast] = useToast();
    const navigate = useNavigate();
    
    const userInfo = useAppSelector((zoom) => zoom.auth.userInfo)
    const [userData, setUserData] = useState<DocumentData | null>(null);
  
    useEffect(() => {
      const getUserData = async () => {
        if (userInfo) {
          const data = await getUserInfo(userInfo);
          setUserData(data);
        }
      };
  
      getUserData();
    }, [userInfo]);
  
    const [meetingName, setMeetingName] = useState("");
    const [selectedUser, setSelectedUser] = useState<Array<UserType>>([]);
    const [startDate, setStartDate] = useState(moment());
    const [size, setSize] = useState(1);
    const [showErrors, setShowErrors] = useState<{
      meetingName: FieldErrorType;
      meetingUsers: FieldErrorType;
    }>({
      meetingName: {
        show: false,
        message: [],
      },
      meetingUsers: {
        show: false,
        message: [],
      },
    });
    const [anyoneCanJoin, setAnyoneCanJoin] = useState(false);
  
    const onUserChange = (selectedOptions: Array<UserType>) => {
      setSelectedUser(selectedOptions);
    };
  
    const validateForm = () => {
      const showErrorsClone = { ...showErrors };
      let errors = false;
      if (!meetingName.length) {
        showErrorsClone.meetingName.show = true;
        showErrorsClone.meetingName.message = ["Por favor ingresa un nombre de reunion"];
        errors = true;
      } else {
        showErrorsClone.meetingName.show = false;
        showErrorsClone.meetingName.message = [];
      }
      if (!selectedUser.length && !anyoneCanJoin) {
        showErrorsClone.meetingUsers.show = true;
        showErrorsClone.meetingUsers.message = ["Por favor invita un usuario"];
        errors = true;
      } else {
        showErrorsClone.meetingUsers.show = false;
        showErrorsClone.meetingUsers.message = [];
      }
      setShowErrors(showErrorsClone);
      return errors;
    };
  
    const createMeeting = async () => {
      if (!validateForm()) {
        const meetingId = generateMeetingID();
        await addDoc(meetingsRef, {
          createdBy: userData?.uid || '',
          meetingId,
          meetingName,
          meetingType: anyoneCanJoin ? "anyone-can-join" : "video-conference",
          invitedUsers: anyoneCanJoin
            ? []
            : selectedUser.map((user: UserType) => user.uid),
          meetingDate: startDate.format("L"),
          maxUsers: anyoneCanJoin ? 100 : size,
          status: true,
        });
        createToast({
          title: anyoneCanJoin
            ? "Reunion abierta creada con exito."
            : "Video conferencia creada con exito.",
          type: "success",
        });
        navigate("/MeetingsDashboard");
      }
    };
  
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Header />
        <EuiFlexGroup justifyContent="center" alignItems="center">
          <EuiForm>
            <EuiFormRow display="columnCompressedSwitch" label="Cualquiera se puede unir">
              <EuiSwitch
                showLabel={false}
                label="Anyone Can Join"
                checked={anyoneCanJoin}
                onChange={(e) => setAnyoneCanJoin(e.target.checked)}
                compressed
              />
            </EuiFormRow>
  
            <MeetingNameField
              label="Nombre de la reunion"
              isInvalid={showErrors.meetingName.show}
              error={showErrors.meetingName.message}
              placeholder="Nombre de la reunion"
              value={meetingName}
              setMeetingName={setMeetingName}
            />
  
            {anyoneCanJoin ? (
              <MeetingMaximumUsersField value={size} setSize={setSize} />
            ) : (
              <MeetingUserField
                label="Invitar usuario"
                isInvalid={showErrors.meetingUsers.show}
                error={showErrors.meetingUsers.message}
                options={users}
                onChange={onUserChange}
                selectedOptions={selectedUser}
                isClearable={false}
                placeholder="Seleccionar usuario"
              />
            )}
            <MeetingDateField selected={startDate} setStartDate={setStartDate} />
            <EuiSpacer />
            <CreateMeetingButtons createMeeting={createMeeting} />
          </EuiForm>
        </EuiFlexGroup>
      </div>
    );
  }