import "./chart.scss";
import {
  ResponsiveContainer,LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend
} from "recharts";

const data = [
  { name: "Ene", Ingresos: 1200 },
  { name: "Feb", Ingresos: 2100 },
  { name: "Mar", Ingresos: 800 },
  { name: "Abr", Ingresos: 1600 },
  { name: "May", Ingresos: 900 },
  { name: "Jun", Ingresos: 3200 },
  
];
export const Chart = () => {
  return (
    <div className="chart">
      <div className="title">Ultimos 6 meses</div>
      <ResponsiveContainer debounce={300} width="100%" height={300}>
      <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Ingresos" stroke="blue" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
  </div>
  )
}
