import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import { DarkModeContextProvider } from './app/context/darkModeContext';
import { AuthContextProvider } from './app/context/AuthContext';



ReactDOM.render(
  <React.StrictMode>
    <DarkModeContextProvider>
      <AuthContextProvider>
        <Provider store={ store }>
          <App />
        </Provider> 
      </AuthContextProvider>
    </DarkModeContextProvider>
    
  </React.StrictMode>,
  document.getElementById("root")
);