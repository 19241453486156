import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiImage, EuiPanel, EuiProvider, EuiSpacer, EuiText, EuiTextColor } from '@elastic/eui'
import animation  from '../../assets/animation.gif';
import logo from '../../assets/logo.png';
import { GoogleAuthProvider,  signInWithPopup } from '@firebase/auth';
import { firebaseAuth, usersRef } from '../../utils/FirebaseConfig';
import { getDocs, query, where } from '@firebase/firestore';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { AuthContext } from '../../app/context/AuthContext';

function Login() {
    const navigate = useNavigate();
    const { dispatch } = useContext(AuthContext);

    const login = async () => {
        const provider = new GoogleAuthProvider();
        const {user: { email } } = await signInWithPopup(firebaseAuth, provider);
        if (email){
            const firestoreQuery = query(usersRef, where("email", "==",email));
            const fetchedUsers = await getDocs(firestoreQuery);
            if (fetchedUsers.docs.length === 0){
                navigate("/login");
                alert("User not found");
            }
            else{
                dispatch({type:"LOGIN", payload:fetchedUsers})
                navigate("/home");
            }
        }    
    }
  return (
    <EuiProvider colorMode="dark">
        <EuiFlexGroup alignItems='center' 
        justifyContent='center' 
        style={{width:"100vw", height:"100vh"}}>
            <EuiFlexItem grow={false}>
                <EuiPanel paddingSize="xl">
                    <EuiFlexGroup justifyContent='center' alignItems='center'>
                        <EuiFlexItem>
                            <EuiImage src={animation} alt="logo" />
                        </EuiFlexItem>

                        <EuiFlexItem>
                            <EuiImage src={logo} alt="logo"  size="300px"/>
                            <EuiSpacer size='s'/>
                            <EuiText textAlign='center' grow={false}>
                                <h3>
                                    <EuiTextColor>Una Plataforma para </EuiTextColor>
                                    <EuiTextColor color="yellow">conectarse</EuiTextColor>
                                </h3>
                            </EuiText>
                            <EuiSpacer size='l'></EuiSpacer>
                            <EuiButton fill onClick={login}> Conectarse con google</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </EuiFlexItem>
        </EuiFlexGroup>
    </EuiProvider>
  )
}

export default Login