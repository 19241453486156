import { getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { machinesRef } from "../utils/FirebaseConfig";
import { MachineType } from "../utils/Types";

function useMachines() {
  const [machines, setMachines] = useState<Array<MachineType>>([]);


  useEffect(() => {
   
      const getMachines = async () => {
        const firestoreQuery = query(machinesRef);
        const data = await getDocs(firestoreQuery);
        const firebaseMachines: Array<MachineType> = [];

        data.forEach((machine) => {
          const machineData: MachineType = machine.data() as MachineType;
          firebaseMachines.push({
            ...machineData,
            
          });
        });
        setMachines(firebaseMachines);
      };
      getMachines();
    
  }, []);     
  return machines;
}

export default useMachines;