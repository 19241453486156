import { EuiBadge,EuiBasicTable, EuiButtonIcon, EuiCopy,EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui";
  
  import { getDocs, query } from "firebase/firestore";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { MeetingType } from "../../utils/Types";
import { meetingsRef } from "../../utils/FirebaseConfig";
import Header from "../../components/MeetingsComponents/Header";
import { getUserInfo } from "../../utils/getUserInfo";
import { useAppSelector } from "../../app/hooks";

 
  export default function Meeting() {
    useAuth();
    const userInfo = useAppSelector((zoom) => zoom.auth.userInfo)

    const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
  
    useEffect(() => {
      const getMyMeetings = async () => {
        const userData = await getUserInfo(userInfo);

        const firestoreQuery = query(meetingsRef);
        const fetchedMeetings = await getDocs(firestoreQuery);

        if (fetchedMeetings.docs.length) {
          const myMeetings: Array<MeetingType> = [];
          fetchedMeetings.forEach((meeting) => {
            const data = meeting.data() as MeetingType;
            if (data.createdBy === userData.uid)
              myMeetings.push(meeting.data() as MeetingType);
            else if (data.meetingType === "anyone-can-join")
              myMeetings.push(meeting.data() as MeetingType);
            else {
              const index = data.invitedUsers.findIndex(
                (user: string) => user === userData.uid
              );
              if (index !== -1) {
                myMeetings.push(meeting.data() as MeetingType);
              }
            }
          });
  
          setMeetings(myMeetings);
        }
      };
      if (userInfo) getMyMeetings();
    }, [userInfo]);
  
    const meetingColumns = [
      {
        field: "meetingName",
        name: "Nombre de la reunion",
      },
      {
        field: "meetingType",
        name: "Tipo de la reunion",
      },
      {
        field: "meetingDate",
        name: "Fecha de la reunion",
      },
      {
        field: "",
        name: "Status",
  
        render: (meeting: MeetingType) => {
          if (meeting.status) {
            if (meeting.meetingDate === moment().format("L")) {
              return (
                <EuiBadge color="success">
                  <Link
                    to={`/join/${meeting.meetingId}`}
                    style={{ color: "black" }}
                  >
                    Unirse ahora
                  </Link>
                </EuiBadge>
              );
            } else if (
              moment(meeting.meetingDate).isBefore(moment().format("L"))
            ) {
              return <EuiBadge color="default">Finalizada</EuiBadge>;
            } else if (moment(meeting.meetingDate).isAfter()) {
              return <EuiBadge color="primary">Por arrancar</EuiBadge>;
            }
          } else return <EuiBadge color="danger">Cancelada</EuiBadge>;
        },
      },
      {
        field: "meetingId",
        name: "Copy Link",
        width: "10%",
        render: (meetingId: string) => {
          return (
            <EuiCopy
              textToCopy={`${process.env.REACT_APP_HOST}/join/${meetingId}`}
            >
              {(copy: any) => (
                <EuiButtonIcon
                  iconType="copy"
                  onClick={copy}
                  display="base"
                  aria-label="meeting-copy"
                />
              )}
            </EuiCopy>
          );
        },
      },
    ];
  
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Header />
        <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
          <EuiFlexItem>
            <EuiPanel>
              <EuiBasicTable items={meetings} columns={meetingColumns} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    );
  }