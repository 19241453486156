import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  getFirestore,
  query,
  where,
  limit,
  getDocs,
  collection,
} from "firebase/firestore";

import "./datatable.scss";
import {
  assistanceColumns,
} from "./datatablesource";
import { firebaseDB } from "../../../utils/FirebaseConfig";

const AssistanceDataTable = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(firebaseDB, "meetings"));
        const fetchedData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const finalRows = await Promise.all(
          fetchedData.map(async (row) => {
            const toReturn = row;
            let createduser = null;
            let inviteduser = null;
            if (row.createdBy) {
              createduser = await getDocs(
                query(
                  collection(db, "users"),
                  where("uid", "==", row.createdBy),
                  limit(1)
                )
              );
            }
            if (row.invitedUsers[0]) {
              inviteduser = await getDocs(
                query(
                  collection(db, "users"),
                  where("uid", "==", row.invitedUsers[0]),
                  limit(1)
                )
              );
            }
            if (createduser && !createduser.empty) {
              toReturn.createdUser = createduser.docs[0].data();
              toReturn.createdUserName = toReturn.createdUser.name;
            }
            if (inviteduser && !inviteduser.empty) {
              toReturn.invitedUser = inviteduser.docs[0].data();
              toReturn.invitedUserName = toReturn.invitedUser.name;
            }
            return toReturn;
          })
        );
        console.log(finalRows, 'D');
        setRows(finalRows);

        setIsLoading(false);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    fetchData();
  }, []);


  if (isLoading) {
    return <div>Cargando...</div>;
  }

  return (
    <div className="datatable">
      <div className="datatableTitle">Asistencias</div>
      <DataGrid
        className="datagrid"
        rows={rows}
        columns={assistanceColumns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default AssistanceDataTable;
