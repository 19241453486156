import { NavigateFunction } from "react-router";
import { BreadCrumbsType } from "./Types";

export const getCreateMeetingBreadCrumbs = (navigate: NavigateFunction): Array<BreadCrumbsType> =>[
    { 
        text:"Dashboard", 
        href:"MeetingsDashboard", 
        onClick: () => { navigate("/MeetingsDashboard"); },
    },{ 
        text:"Crear Reunion", 
    },
]

export const getOneOnOneMeetingBreadCrumbs = (
    navigate: NavigateFunction
  ): Array<BreadCrumbsType> => [
    {
      text: "Dashboard",
      href: "MeetingsDashboard",
      onClick: () => {
        navigate("/MeetingsDashboard");
      },
    },
    {
      text: "Crear Reunion",
      href: "createMeeting",
      onClick: () => {
        navigate("/createMeeting");
      },
    },
    {
      text: "Crear Reunion 1 a 1",
    },
  ];

  export const getVideoConferenceBreadCrumbs = (
    navigate: NavigateFunction
  ): Array<BreadCrumbsType> => [
    {
      text: "Dashboard",
      href: "MeetingsDashboard",
      onClick: () => {
        navigate("/MeetingsDashboard");
      },
    },
    {
      text: "Crear Reunion",
      href: "/createMeeting",
      onClick: () => {
        navigate("/createVideoConference");
      },
    },
    {
      text: "Crear Video Conferencia",
    },
  ];
  
  export const getMyMeetingsBreadCrumbs = (
    navigate: NavigateFunction
  ): Array<BreadCrumbsType> => [
    {
      text: "Dashboard",
      href: "MeetingsDashboard",
      onClick: () => {
        navigate("/MeetingsDashboard");
      },
    },
    {
      text: "Mis Reuniones",
    },
  ];

  export const getMeetingsBreadCrumbs = (
    navigate: NavigateFunction
  ): Array<BreadCrumbsType> => [
    {
      text: "Dashboard",
      href: "MeetingsDashboard",
      onClick: () => {
        navigate("/MeetingsDashboard");
      },
    },
    {
      text: "Reuniones",
    },
  ];