import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./sidebar.scss";
import PeopleIcon from '@mui/icons-material/People';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import HistoryIcon from '@mui/icons-material/History';
import PersonIcon from '@mui/icons-material/Person';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HelpIcon from '@mui/icons-material/Help';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StorageIcon from '@mui/icons-material/Storage';
import { firebaseAuth } from '../../../utils/FirebaseConfig';
import { signOut } from 'firebase/auth';
import { DarkModeContext } from '../../../app/context/darkModeContext';
import { getCurrentUser } from '../../../utils/getCurrentUser';

const Sidebar = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(DarkModeContext);
  const [accessLevel, setAccessLevel] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getCurrentUser();
        console.log("user level access: ", user.accessLevel)
        setAccessLevel(user.accessLevel);
      } catch (error) {
        console.error('Error occurred:', error);
      }
    };

    fetchData();
  }, []);

  const logout = () => {
    signOut(firebaseAuth);
  };

  if (accessLevel === '0'){

    return (
      <div className='sidebar'>
        <div className="top"><span className="logo">ONFIXS</span></div>
        <div className="center">
          <ul>
            <p className='title'>PRINCIPAL</p>
            <li onClick={() => navigate("/home")}>
              <DashboardIcon className='icon' />
              <span>Panel Principal</span>
            </li>
            <p className='title'>BASES DE DATOS</p>
            <li onClick={() => navigate("/clients")}>
              <PeopleIcon className='icon'/>
              <span>Clientes</span>
            </li>
  
            <li  onClick={() => navigate("/technicians")}>
              <EngineeringIcon className='icon'/>
              <span>
                Tecnicos
              </span>
            </li>
  
            <li onClick={() => navigate("/machines")}>
              <PrecisionManufacturingIcon className='icon'/>
              <span>
  
                Maquinas
              </span>
            </li>
  
            <li onClick={() => navigate("/assistances")} >
              <HistoryIcon className='icon'/>
              <span>
                Historial De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/users")}>
              <PersonIcon className='icon'/>
              <span>
                Usuarios
              </span>
            </li>
  
            <p className='title'>APPS</p>
            <li onClick={() => navigate("/meetingsdashboard")}>
              <VideoCallIcon className='icon'/>
              <span>
                Menu De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/report")}>
              <EditNoteIcon className='icon'/>
              <span>
                Crear Informe
              </span>
            </li>
  
            <li>
              <FormatListNumberedIcon className='icon'/>
              <span>
                Mis Informes
              </span>
            </li>
  
            <li>
              <ScheduleIcon className='icon'/>
              <span>
                Mis Asistencias
              </span>
            </li>
  
            <li>
              <StorageIcon className='icon'/>
              <span>
              Wiki Máquinas
              </span>
            </li>
  
            <p className='title'>USUARIO</p>
            <li>
              <AccountBoxIcon className='icon'/>
              <span>
                Perfil
              </span>
            </li>
  
            <li>
              <HelpIcon className='icon'/>
              <span>
                Ayuda
              </span>
            </li>
  
            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeX8Gne4N1QPbZhWjNMJKy_rN8o-48kBlKNB9yrm1yYhD6SLw/viewform?usp=sf_link'}>
              <FeedbackIcon className='icon'/>
              <span>
                Feedback
              </span>
            </li>
  
            <li onClick={() => {logout()}}>
              <ExitToAppIcon className='icon'/>
              <span>
                Salir
              </span>
            </li>
  
          </ul>
        </div>
        <div className="bottom">
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "LIGHT" })}
          ></div>
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "DARK" })}
          ></div>
        </div>
      </div>
    )
    }

  else if (accessLevel === '1'){
    return (
      <div className='sidebar'>
        <div className="top"><span className="logo">ONFIXS</span></div>
        <div className="center">
          <ul>
            <p className='title'>PRINCIPAL</p>
            <li onClick={() => navigate("/home")}>
              <DashboardIcon className='icon' />
              <span>Panel Principal</span>
            </li>
            <p className='title'>BASES DE DATOS</p>
            <li onClick={() => navigate("/clients")}>
              <PeopleIcon className='icon'/>
              <span>Clientes</span>
            </li>
  
            <li  onClick={() => navigate("/technicians")}>
              <EngineeringIcon className='icon'/>
              <span>
                Tecnicos
              </span>
            </li>
  
            <li onClick={() => navigate("/machines")}>
              <PrecisionManufacturingIcon className='icon'/>
              <span>
  
                Maquinas
              </span>
            </li>
  
            <li onClick={() => navigate("/assistances")} >
              <HistoryIcon className='icon'/>
              <span>
                Historial De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/users")}>
              <PersonIcon className='icon'/>
              <span>
                Usuarios
              </span>
            </li>
  
            <p className='title'>APPS</p>
            <li onClick={() => navigate("/meetingsdashboard")}>
              <VideoCallIcon className='icon'/>
              <span>
                Menu De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/report")}>
              <EditNoteIcon className='icon'/>
              <span>
                Crear Informe
              </span>
            </li>
  
            <li>
              <FormatListNumberedIcon className='icon'/>
              <span>
                Mis Informes
              </span>
            </li>
  
            <li>
              <ScheduleIcon className='icon'/>
              <span>
                Mis Asistencias
              </span>
            </li>
  
            <li>
              <StorageIcon className='icon'/>
              <span>
              Wiki Máquinas
              </span>
            </li>
  
            <p className='title'>USUARIO</p>
            <li>
              <AccountBoxIcon className='icon'/>
              <span>
                Perfil
              </span>
            </li>
  
            <li>
              <HelpIcon className='icon'/>
              <span>
                Ayuda
              </span>
            </li>
  
            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeX8Gne4N1QPbZhWjNMJKy_rN8o-48kBlKNB9yrm1yYhD6SLw/viewform?usp=sf_link'}>
              <FeedbackIcon className='icon'/>
              <span>
                Feedback
              </span>
            </li>
  
            <li onClick={() => {logout()}}>
              <ExitToAppIcon className='icon'/>
              <span>
                Salir
              </span>
            </li>
  
          </ul>
        </div>
        <div className="bottom">
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "LIGHT" })}
          ></div>
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "DARK" })}
          ></div>
        </div>
      </div>
    )
  }

  else if (accessLevel === '2'){
    return (
      <div className='sidebar'>
        <div className="top"><span className="logo">ONFIXS</span></div>
        <div className="center">
          <ul>
            <p className='title'>PRINCIPAL</p>
            <li onClick={() => navigate("/home")}>
              <DashboardIcon className='icon' />
              <span>Panel Principal</span>
            </li>
            <p className='title'>BASES DE DATOS</p>
  
            <li  onClick={() => navigate("/technicians")}>
              <EngineeringIcon className='icon'/>
              <span>
                Tecnicos
              </span>
            </li>
  
            <li onClick={() => navigate("/machines")}>
              <PrecisionManufacturingIcon className='icon'/>
              <span>
  
                Maquinas
              </span>
            </li>
  
            <li onClick={() => navigate("/assistances")} >
              <HistoryIcon className='icon'/>
              <span>
                Historial De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/users")}>
              <PersonIcon className='icon'/>
              <span>
                Usuarios
              </span>
            </li>
  
            <p className='title'>APPS</p>
            <li onClick={() => navigate("/meetingsdashboard")}>
              <VideoCallIcon className='icon'/>
              <span>
                Menu De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/report")}>
              <EditNoteIcon className='icon'/>
              <span>
                Crear Informe
              </span>
            </li>
  
            <li>
              <FormatListNumberedIcon className='icon'/>
              <span>
                Mis Informes
              </span>
            </li>
  
            <li>
              <ScheduleIcon className='icon'/>
              <span>
                Mis Asistencias
              </span>
            </li>
  
            <li>
              <StorageIcon className='icon'/>
              <span>
              Wiki Máquinas
              </span>
            </li>
  
            <p className='title'>USUARIO</p>
            <li>
              <AccountBoxIcon className='icon'/>
              <span>
                Perfil
              </span>
            </li>
  
            <li>
              <HelpIcon className='icon'/>
              <span>
                Ayuda
              </span>
            </li>
  
            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeX8Gne4N1QPbZhWjNMJKy_rN8o-48kBlKNB9yrm1yYhD6SLw/viewform?usp=sf_link'}>
              <FeedbackIcon className='icon'/>
              <span>
                Feedback
              </span>
            </li>
  
            <li onClick={() => {logout()}}>
              <ExitToAppIcon className='icon'/>
              <span>
                Salir
              </span>
            </li>
  
          </ul>
        </div>
        <div className="bottom">
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "LIGHT" })}
          ></div>
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "DARK" })}
          ></div>
        </div>
      </div>
    )
  }

  else {
    return (
      <div className='sidebar'>
        <div className="top"><span className="logo">ONFIXS</span></div>
        <div className="center">
          <ul>
            <p className='title'>PRINCIPAL</p>
            <li onClick={() => navigate("/home")}>
              <DashboardIcon className='icon' />
              <span>Panel Principal</span>
            </li>
            <p className='title'>BASES DE DATOS</p>
  
            <li onClick={() => navigate("/machines")}>
              <PrecisionManufacturingIcon className='icon'/>
              <span>
  
                Maquinas
              </span>
            </li>
  
            <li onClick={() => navigate("/assistances")} >
              <HistoryIcon className='icon'/>
              <span>
                Historial De Asistencias
              </span>
            </li>
  
            <p className='title'>APPS</p>
            <li onClick={() => navigate("/meetingsdashboard")}>
              <VideoCallIcon className='icon'/>
              <span>
                Menu De Asistencias
              </span>
            </li>
  
            <li onClick={() => navigate("/report")}>
              <EditNoteIcon className='icon'/>
              <span>
                Crear Informe
              </span>
            </li>
  
            <li>
              <FormatListNumberedIcon className='icon'/>
              <span>
                Mis Informes
              </span>
            </li>
  
            <li>
              <ScheduleIcon className='icon'/>
              <span>
                Mis Asistencias
              </span>
            </li>
  
            <li>
              <StorageIcon className='icon'/>
              <span>
              Wiki Máquinas
              </span>
            </li>
  
            <p className='title'>USUARIO</p>
            <li>
              <AccountBoxIcon className='icon'/>
              <span>
                Perfil
              </span>
            </li>
  
            <li>
              <HelpIcon className='icon'/>
              <span>
                Ayuda
              </span>
            </li>
  
            <li onClick={()=> window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeX8Gne4N1QPbZhWjNMJKy_rN8o-48kBlKNB9yrm1yYhD6SLw/viewform?usp=sf_link'}>
              <FeedbackIcon className='icon'/>
              <span>
                Feedback
              </span>
            </li>
  
            <li onClick={() => {logout()}}>
              <ExitToAppIcon className='icon'/>
              <span>
                Salir
              </span>
            </li>
  
          </ul>
        </div>
        <div className="bottom">
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "LIGHT" })}
          ></div>
          <div
            className="colorOption"
            onClick={() => dispatch({ type: "DARK" })}
          ></div>
        </div>
      </div>
    )
  }
  }

export default Sidebar