import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, getFirestore} from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCXCd4iWScO4xBNJA-FfN-d_bcxn9aolhE",
  authDomain: "remote-tech-solution.firebaseapp.com",
  projectId: "remote-tech-solution",
  storageBucket: "remote-tech-solution.appspot.com",
  messagingSenderId: "82960103836",
  appId: "1:82960103836:web:6dd1bc8070b9d14415b059",
  measurementId: "G-CKCT66P3F7"
};

const app = initializeApp(firebaseConfig);


export const firebaseAuth = getAuth(app); 
export const firebaseDB = getFirestore(app);
export const storage = getStorage(app);
export const usersRef = collection(firebaseDB, "users");
export const meetingsRef = collection(firebaseDB, "meetings");
export const machinesRef = collection(firebaseDB, "machines");


