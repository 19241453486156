export const userInputs = [
    {
        id: 'name',
        label:'Nombre y Apellido',
        type: 'text',
        placeholder: 'Nombre y Apellido'
    }, {
        id: 'email',
        label:'Email',
        type: 'email',
        placeholder: 'Email'
    }, {
        id: 'rol',
        label:'Rol',
        type: 'text',
        placeholder: 'Rol'
    }, {
        id: 'accessLevel',
        label:'Nivel de Acceso',
        type: 'text',
        placeholder: 'Nivel'
    } , {
        id: 'company',
        label:'Empresa',
        type: 'text',
        placeholder: 'Empresa'
    }, {
        id: 'phone',
        label:'Telefono',
        type: 'text',
        placeholder: 'Tel'
    }
];

export const clientInputs = [
    {
        id: 'id',
        label:'ID Cliente',
        type: 'text',
        placeholder: 'ID'
    }, {
        id: 'client',
        label:'Nombre de la Empresa',
        type: 'text',
        placeholder: 'Empresa'
    }, {
        id: 'country',
        label:'Pais',
        type: 'text',
        placeholder: 'Pais'
    } , {
        id: 'state',
        label:'Estado/Provincia',
        type: 'text',
        placeholder: 'Estado/Provincia'
    }, {
        id: 'city',
        label:'Ciudad',
        type: 'text',
        placeholder: 'Ciudad'
    }, {
        id: 'address',
        label:'Direccion',
        type: 'text',
        placeholder: 'Direccion'
    }, {
        id: 'contactPhone',
        label:'Celular Contacto',
        type: 'text',
        placeholder: 'Celular Contacto'
    }, {
        id: 'contactName',
        label:'Nombre Contacto',
        type: 'text',
        placeholder: 'Nombre Contacto'
    }, {
        id: 'rol',
        label:'Puesto',
        type: 'text',
        placeholder: 'Puesto'
    }
];


export const machineInputs = [  
     {
        id: "model",
        label:'Modelo',
        type: 'text',
        placeholder: 'Modelo'
    }, {
        id: "serialNumber",
        label:'Numero de Serie',
        type: 'text',
        placeholder: 'Numero de Serie'
    } , {
        id: 'client',
        label:'Cliente',
        type: 'text',
        placeholder: 'Cliente'
    },{
        id: "manual",
        label:'Link del Manual',
        type: 'text',
        placeholder: 'Link'
    },
];
