import { DocumentData, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";
import { usersRef } from "../utils/FirebaseConfig";
import { UserType } from "../utils/Types";
import { getUserInfo } from "../utils/getUserInfo";

function useFetchUsers() {
  const [users, setUsers] = useState<Array<UserType>>([]);
  const userInfo = useAppSelector((zoom) => zoom.auth.userInfo)
  const [userData, setUserData] = useState<DocumentData | null>(null);

  useEffect(() => {
    const getUserData = async () => {
      if (userInfo) {
        const data = await getUserInfo(userInfo);
        setUserData(data);
      }
    };

    getUserData();
  }, [userInfo]);

  useEffect(() => {
    if (userData?.uid) {
      const getUser = async () => {
        const firestoreQuery = query(usersRef, where("uid", "!=", userData?.uid || ""));
        const data = await getDocs(firestoreQuery);
        const firebaseUsers: Array<UserType> = [];

        data.forEach((user) => {
          const userData: UserType = user.data() as UserType;
          firebaseUsers.push({
            ...userData,
            label: userData.name,
          });
        });
        setUsers(firebaseUsers);
      };
      getUser();
    }
  });
  return [users];
}

export default useFetchUsers;