export const userColumns = [

  {
    field: "userName",
    headerName: "Usuario",
    width: 100,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.username}
        </div>
      );
    },
  },
  {
    field: "name",
    headerName: "Nombre Completo",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 230,
  },

  {
    field: "rol",
    headerName: "Rol",
    width: 150,
  },
  {
    field: "accessLevel",
    headerName: "Acceso",
    width: 100,
  },
  {
    field: "company",
    headerName: "Empresa",
    width: 150,
  },
  {
    field: "phone",
    headerName: "Telefono",
    width: 150,
  },
];

export const clientColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "client",
    headerName: "Cliente",
    width: 130,
  },
  {
    field: "country",
    headerName: "Pais",
    width: 130,
  },

  {
    field: "state",
    headerName: "Estado/Provincia",
    width: 130,
  },
  {
    field: "city",
    headerName: "Ciudad",
    width: 130,
  },
  {
    field: "address",
    headerName: "Direccion",
    width: 130,
  },
  {
    field: "contactName",
    headerName: "Nombre de Contacto",
    width: 130,
  },
  {
    field: "contactPhone",
    headerName: "Celular de Contacto",
    width: 130,
  },
  {
    field: "rol",
    headerName: "Rol",
    width: 140,
  },
];

export const machineColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "model",
    headerName: "Modelo",
    width: 200,
  },
  {
    field: "serialNumber",
    headerName: "Numero de Serie",
    width: 200,
  },
  {
    field: "client",
    headerName: "ID Cliente",
    width: 200,
  },
  {
    field: "manual",
    headerName: "Manual",
    width: 200,
  },
];

export const assistanceColumns = [
  { field: "meetingName", headerName: "Asistencia", width: 150 },
  {
    field: "createdUserName",
    headerName: "ID Tecnico",
    width: 150,
  },
  {
    field: "invitedUserName",
    headerName: "Operador",
    width: 150,
  },
  {
    field: "invitedUser",
    headerName: "Cliente",
    width: 150,
    valueFormatter: (params) => {
      return params.value?.company;
    },
  },
  {
    field: "model",
    headerName: "Modelo",
    width: 200,
  },
  {
    field: "serialNumber",
    headerName: "Maquina",
    width: 200,
  },
];

export const technicianColumns = [
  { field: "id", headerName: "ID", width: 180 },
  {
    field: "email",
    headerName: "Email",
    width: 200,
  },

  {
    field: "name",
    headerName: "Nombre Completo",
    width: 200,
  },

  {
    field: "company",
    headerName: "Empresa",
    width: 200,
  },
];
