/* eslint-disable jsx-a11y/anchor-is-valid */
import useScript from "../hooks/useScript";
import { useNavigate } from "react-router-dom";
import useStyle from "../hooks/useStyle";

function LandingPage() {
  useScript("js/landingpage.js");
  useStyle('css/style.css');
  const navigate = useNavigate();
  
  return (
      <body id="body" data-spy="scroll" data-target=".navbar" data-offset="100">
  <header id="header-section">
    <nav className="navbar navbar-expand-lg pl-3 pl-sm-0" id="navbar">
    <div className="container">
      <div className="navbar-brand-wrapper d-flex w-100">
        <h1>ONFIXS</h1>
        <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="mdi mdi-menu navbar-toggler-icon"></span>
        </button> 
      </div>
      <div className="collapse navbar-collapse navbar-menu-wrapper" id="navbarSupportedContent">
        <ul className="navbar-nav align-items-lg-center align-items-start ml-auto">
          <li className="d-flex align-items-center justify-content-between pl-4 pl-lg-0">
            <div className="navbar-collapse-logo">
              <img src="images/Group2.svg" alt=""/>
            </div>
            <button className="navbar-toggler close-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="mdi mdi-close navbar-toggler-icon pl-5"></span>
            </button>
          </li>
          
          <li className="nav-item">
            <a className="nav-link" href="#features-section">Como funciona</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#digital-marketing-section">Nosotros</a>  
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#feedback-section">Testimonios</a>
          </li>
          <li className="nav-item btn-contact-us pl-4 pl-lg-0">
            <button className="btn btn-info" data-toggle="modal" data-target="#exampleModal" onClick={()=>{navigate("/login")}}>Ingresar</button>
          </li>
        </ul>
      </div>
    </div> 
    </nav>   
  </header>
  <div className="banner" >
    <div className="container">
      <h1 className="font-weight-semibold">Cuida tus equipos <br/> desde cualquier lugar con nosotros</h1>
      <h6 className="font-weight-normal text-muted pb-3">Nuestro software de asistencia remota es una solución eficiente y segura para el mantenimiento, cuidado y reparacion de tus equipos.</h6>
      <div>
        <button className="btn btn-opacity-light mr-1" onClick={()=> window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSfUVv0M-M_RY7c3wZMG0BchlFMocy1wU1vA4f0NUng33z-ibw/viewform?usp=sf_link/")}>Comenzar</button>
        <button className="btn btn-opacity-success ml-1" onClick={()=> window.location.replace("https://docs.google.com/forms/d/e/1FAIpQLSfUVv0M-M_RY7c3wZMG0BchlFMocy1wU1vA4f0NUng33z-ibw/viewform?usp=sf_link/")}>Mas informacion</button>
      </div>
      <img src="images/Group171.svg" alt="" className="img-fluid"/>
    </div>
  </div>
  <div className="content-wrapper">
    <div className="container">
      <section className="features-overview" id="features-section" >
        <div className="content-header">
          <h2>Como funciona</h2>
          <h6 className="section-subtitle text-muted">Nuestro software de asistencia remota utiliza una conexión segura a 
            internet para establecer una comunicación en tiempo real entre el equipo remoto y el usuario, facilitando todo tipo de asistencias
          al equipo del cliente.</h6>
        </div>
        <div className="d-md-flex justify-content-between">
          <div className="grid-margin d-flex justify-content-start">
            <div className="features-width">
              <img src="images/7956.jpg" alt="" className="img-icons" height="62px" width="62px"/>
              <h5 className="py-3">Coordinamos una<br/>reunion</h5>
              <p className="text-muted">En este paso hablamos de tus requerimientos y preparamos la mejor solucion para vos y tus equipos</p>
              <a href="#"><p className="readmore-link">Leer mas</p></a>  
            </div>
          </div>
          <div className="grid-margin d-flex justify-content-center">
            <div className="features-width">
              
              <img src="images/7954.jpg" alt="" className="img-icons" height="62px" width="62px"/>
              <h5 className="py-3">Envio de<br/>equipos</h5>
              <p className="text-muted">Una vez que entendemos bien las nececidades de tu proyecto te mandamos el equipo que vas a necesitar.</p>
              <a href="#"><p className="readmore-link">Leer mas</p></a>
            </div>
          </div>
          <div className="grid-margin d-flex justify-content-end">
            <div className="features-width">
              <img src="images/7955.jpg" alt="" className="img-icons" height="62px" width="62px"/>
              <h5 className="py-3">Proteccion de tus<br/>equipos</h5>
              <p className="text-muted">Listo! ya esta todo preparado y tus equipos estan listos para ser cuidados con nuestro software</p>
              <a href="#"><p className="readmore-link">Leer mas</p></a>
            </div>
          </div>
        </div>
      </section>     
      <section className="digital-marketing-service" id="digital-marketing-section">
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 grid-margin grid-margin-lg-0" data-aos="fade-right">
            <h3 className="m-0">Nuestra mision:</h3>
            <div className="col-lg-7 col-xl-6 p-0">
              <p className="py-4 m-0 text-muted">Nuestra misión consiste en optimizar los costos y tiempos de las empresas mediante soluciones tecnológicas innovadoras que faciliten el trabajo remoto</p>
              <p className="font-weight-medium text-muted">En definitiva, nos dedicamos a hacer posible el trabajo remoto de manera eficaz y rentable, contribuyendo así a un futuro laboral equilibrado y sostenible.</p>
            </div>    
          </div>
          <div className="col-12 col-lg-5 p-0 img-digital grid-margin grid-margin-lg-0" data-aos="fade-left">
            <img src="images/1.jpg" alt="" className="img-fluid" width="400px" height="400px"/>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-lg-7 text-center flex-item grid-margin" data-aos="fade-right">
            <img src="images/2.jpg" alt="" className="img-fluid" width="400px" height="400px"/>
          </div>
          <div className="col-12 col-lg-5 flex-item grid-margin" data-aos="fade-left">
            <h3 className="m-0">Nuestra vision:</h3>
            <div className="col-lg-9 col-xl-8 p-0">
              <p className="py-4 m-0 text-muted">Nuestra visión consiste en liderar la transformación digital en el ámbito empresarial, facilitando a las empresas la adopción de métodos de trabajo más eficientes y sostenibles a través del empleo remoto.</p>
              <p className="pb-2 font-weight-medium text-muted">Nos enfocamos en ser una compañía vanguardista, desarrollando soluciones tecnológicas innovadoras que revolucionen la gestión de equipos y colaboradores en las organizaciones.</p>
            </div>
          </div>
        </div>
      </section>     
      
      <section className="customer-feedback" id="feedback-section">
        <div className="row">
          <div className="col-12 text-center pb-5">
            <h2>Que dicen nuestros clientes</h2>
            <h6 className="section-subtitle text-muted m-0">Lorem ipsum dolor sit amet, tincidunt vestibulum.</h6>
          </div>
          <div className="owl-carousel owl-theme grid-margin">
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face2.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Tony Martinez</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face3.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Sophia Armstrong</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face20.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Cody Lambert</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face15.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Cody Lambert</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face16.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Cody Lambert</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face1.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Tony Martinez</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face2.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Tony Martinez</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face3.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Sophia Armstrong</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
              <div className="card customer-cards">
                <div className="card-body">
                  <div className="text-center">
                    <img src="images/face20.jpg" width="89" height="89" alt="" className="img-customer"/>
                    <p className="m-0 py-3 text-muted">Lorem ipsum dolor sit amet, tincidunt vestibulum. Fusce egeabus consectetuer turpis, suspendisse.</p>
                    <div className="content-divider m-auto"></div>
                    <h6 className="card-title pt-3">Cody Lambert</h6>
                    <h6 className="customer-designation text-muted m-0">Marketing Manager</h6>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      <section className="contact-us" id="contact-section">
        <div className="contact-us-bgimage grid-margin" >
          <div className="pb-4">
            <h4 className="px-3 px-md-0 m-0" data-aos="fade-down">Pongamonos en contacto hoy!</h4>
          </div>
          <div data-aos="fade-up">
            <a className="btn btn-rounded btn-outline-danger" href="https://docs.google.com/forms/d/e/1FAIpQLSfUVv0M-M_RY7c3wZMG0BchlFMocy1wU1vA4f0NUng33z-ibw/viewform?usp=sf_link/">Contactenos</a>  
          </div>          
        </div>
      </section>
      <section className="contact-details" id="contact-details-section">
        <div className="row text-center text-md-left">
          <div className="col-12 col-md-6 col-lg-3 grid-margin">
            <div className="pt-2">
              <p className="text-muted m-0">contacto@onfixs.com</p>
              <p className="text-muted m-0">+54 9 3541 215719</p>
            </div>         
          </div>
          <div className="col-12 col-md-6 col-lg-3 grid-margin">
            <h5 className="pb-2">Contactanos</h5>
            <p className="text-muted">No te pierdas ninguna noticia de nuestro producto</p>
            <form>
              <input autoComplete="off" type="text" className="form-control" id="Email" placeholder="Email"/>
            </form>
            <div className="pt-3">
              <button className="btn btn-dark">Suscribirse</button>
            </div>   
          </div>
          <div className="col-12 col-md-6 col-lg-3 grid-margin">
            <h5 className="pb-2">Nuestras guias</h5>
            <a href="#"><p className="m-0 pb-2">Terminos de uso</p></a>   
            <a href="#" ><p className="m-0 pt-1 pb-2">Politica de privacidad</p></a> 

          </div>
          <div className="col-12 col-md-6 col-lg-3 grid-margin">
              <h5 className="pb-2">Nuestra direccion</h5>
             {/* <p className="text-muted">Av. Italia 800 Parque industrial Fase 2 <br/>Malagueño, Cordoba, Argentina</p> */}
              <div className="d-flex justify-content-center justify-content-md-start">
                <a href="#"><span className="mdi mdi-facebook"></span></a>
                <a href="#"><span className="mdi mdi-twitter"></span></a>
                <a href="#"><span className="mdi mdi-instagram"></span></a>
                <a href="#"><span className="mdi mdi-linkedin"></span></a>
              </div>
          </div>
        </div>  
      </section>
      <footer className="border-top">
        <p className="text-center text-muted pt-4">Copyright © 2023<a href="https://onfixs.com/" className="px-1">ONFIXS.</a>All rights reserved.</p>
      </footer>
    </div> 
  </div>
      </body>

  );
}

export default LandingPage;
