import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";
import { getDocs, query, where } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useToast from "../../hooks/useToast";
import { meetingsRef } from "../../utils/FirebaseConfig";
import { generateMeetingID } from "../../utils/GenerateMeetingId";
import useAuth from "../../hooks/useAuth";
import { getCurrentUser } from "../../utils/getCurrentUser";

export default function JoinMeeting() {
  useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [createToast] = useToast();
  const [isAllowed, setIsAllowed] = useState(false);
  const [user, setUser] = useState<any>(undefined);
  const [userLoaded, setUserLoaded] = useState(false);

  
  useEffect(() => {
    const getMeetingData = async () => {
      setUser(await getCurrentUser())
      if(user){
        setUserLoaded(true);
      }
      
      if (params.id && userLoaded) {
        const firestoreQuery = query(
          meetingsRef,
          where("meetingId", "==", params.id)
        );
        const fetchedMeetings = await getDocs(firestoreQuery);

        if (fetchedMeetings.docs.length) {
          const meeting = fetchedMeetings.docs[0].data();
          const isCreator = meeting.createdBy === user?.uid;
          if (meeting.meetingType === "1-on-1") {
            if (meeting.invitedUsers[0] === user?.uid || isCreator) {
              if (meeting.meetingDate === moment().format("L")) {
                setIsAllowed(true);
              } else if (
                moment(meeting.meetingDate).isBefore(moment().format("L"))
              ) {
                createToast({ title: "La reunion a finalizado.", type: "danger" });
                navigate(user ? "/MeetingsDashboard" : "/login");
              } else if (moment(meeting.meetingDate).isAfter()) {
                createToast({
                  title: `La reunion es el ${meeting.meetingDate}`,
                  type: "warning",
                });
                navigate(user ? "/MeetingsDashboard" : "/login");
              }
            } else navigate(user ? "/MeetingsDashboard" : "/login");
          } else if (meeting.meetingType === "video-conference") {
            const index = meeting.invitedUsers.findIndex(
              (invitedUser: string) => invitedUser === user?.uid
            );
            if (index !== -1 || isCreator) {
              if (meeting.meetingDate === moment().format("L")) {
                setIsAllowed(true);
              } else if (
                moment(meeting.meetingDate).isBefore(moment().format("L"))
              ) {
                createToast({ title: "La reunion a finalizado.", type: "danger" });
                navigate(user ? "/MeetingsDashboard" : "/login");
              } else if (moment(meeting.meetingDate).isAfter()) {
                createToast({
                  title: `La reunion es el ${meeting.meetingDate}`,
                  type: "warning",
                });
              }
            } else {
              createToast({
                title: `No estas invitado a esta reunion.`,
                type: "danger",
              });
              navigate(user ? "/MeetingsDashboard" : "/login");
            }
          } else {
            setIsAllowed(true);
          }
        }
      }
    };
    getMeetingData();
  }, [params.id, user, userLoaded, createToast, navigate]);

  const appId = 956249993;
  const serverSecret = "9723b3fe56b9dafdee93cd4c9edec935";

  const myMeeting = async (element: any) => {
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
      appId,
      serverSecret,
      params.id as string,
      user?.uid ? user.uid : generateMeetingID(),
      user?.displayName ? user.displayName : generateMeetingID()
    );
    const zp = ZegoUIKitPrebuilt.create(kitToken);

    zp?.joinRoom({
        container: element,
        maxUsers: 50,
        sharedLinks: [{
          name: "Link Personal",
          url: window.location.origin,
        }],
        scenario: { mode: ZegoUIKitPrebuilt.VideoConference },
        showTurnOffRemoteCameraButton: true,
        showTurnOffRemoteMicrophoneButton: true,
        showRemoveUserButton: true,
      });
    }

  return isAllowed ? (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <div
        className="myCallContainer"
        ref={myMeeting}
        style={{ width: "100%", height: "100vh" }}
      ></div>
    </div>
  ) : (
    <></>
  );
}