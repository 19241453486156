import React from 'react'
import { useNavigate } from 'react-router';
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiImage } from "@elastic/eui";
import dashboard1 from "../../assets/dashboard1.png";
import dashboard2 from "../../assets/dashboard2.png";
import dashboard3 from "../../assets/dashboard3.png";
import useAuth from '../../hooks/useAuth';
import Header from "../../components/MeetingsComponents/Header";

function MeetingsDashboard() {
  useAuth();
  const navigate = useNavigate();
  return (   
    <>
    <>
      <div style={{ display: "flex", height: "100vh",flexDirection: "column"}}>
        <Header />
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          style={{ margin: "5vh 10vw" }}
        >
          <EuiFlexItem>
            <EuiCard
              icon={<EuiImage src={dashboard1} alt="icon" size="5rem" />}
              title={`Crear Reunion`}
              description="Crear una nueva reunion e invitar gente."
              onClick={() => navigate("/createMeeting")}
              paddingSize="xl"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              icon={<EuiImage src={dashboard2} alt="icon" size="100%" />}
              title={`Mis Reuiniones`}
              description="Ve las reuniones que creaste."
              onClick={() => navigate("/mymeetings")}
              paddingSize="xl"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              icon={<EuiImage src={dashboard3} alt="icon" size="5rem" />}
              title={`Reuniones`}
              description="Ve las reuniones a las que has sido invitado."
              onClick={() => navigate("/meetings")}
              paddingSize="xl"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </>
    </>
  )
}

export default MeetingsDashboard