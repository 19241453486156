import React from 'react';
import "./list.scss";
import Sidebar from '../../../components/MenuComponents/Sidebar/Sidebar';
import Navbar from '../../../components/MenuComponents/Navbar/Navbar';
import useAuth from '../../../hooks/useAuth';
import AssistanceDataTable from '../../../components/MenuComponents/Datatable/AssistanceDataTable'; 

const AssistanceList = () => {
  useAuth();
  return (
    <div className='list'>
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <AssistanceDataTable />
      </div>
    </div>
  )
}

export default AssistanceList;