import { EuiBadge, EuiBasicTable, EuiButtonIcon, EuiCopy, EuiFlexGroup, EuiFlexItem, EuiPanel } from "@elastic/eui";
  import { getDocs, query, where } from "firebase/firestore";
  import moment from "moment";
  import React, { useEffect, useState, useCallback } from "react";
  import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useAppSelector } from "../../app/hooks";
import { MeetingType } from "../../utils/Types";
import { meetingsRef } from "../../utils/FirebaseConfig";
import Header from "../../components/MeetingsComponents/Header";
import EditFlyout from "../../components/MeetingsComponents/EditFlyout";
import { getUserInfo } from "../../utils/getUserInfo";
  
  export default function MyMeetings() {
    useAuth();
    const userInfo = useAppSelector((zoom) => zoom.auth.userInfo);
    const [meetings, setMeetings] = useState<Array<MeetingType>>([]);
    const [showEditFlyout, setShowEditFlyout] = useState(false);
    const [editMeeting, setEditMeeting] = useState<MeetingType>();

    const getMyMeetings = useCallback(async () => {
      if (!userInfo) {
        return;
      }
      
      const userData = await getUserInfo(userInfo);
      const firestoreQuery = query(
        meetingsRef,
        where("createdBy", "==", userData.uid)
      );
      
      const fetchedMeetings = await getDocs(firestoreQuery);
      if (fetchedMeetings.docs.length) {
        const myMeetings: Array<MeetingType> = [];
        fetchedMeetings.forEach((meeting) => {
          myMeetings.push({
            docId: meeting.id,
            ...(meeting.data() as MeetingType),
          });
        });
        setMeetings(myMeetings);
      }
    }, [userInfo]);
    
    useEffect(() => {
      if (userInfo) {
        getMyMeetings();
      }
    }, [userInfo, getMyMeetings]);
  
    const openEditFlyout = (meeting: MeetingType) => {
      setShowEditFlyout(true);
      setEditMeeting(meeting);
    };
  
    const closeEditFlyout = (dataChanged = false) => {
      setShowEditFlyout(false);
      setEditMeeting(undefined);
      if (dataChanged) getMyMeetings();
    };
  
    const meetingColumns = [
      {
        field: "meetingName",
        name: "Nombre de la reunion",
      },
      {
        field: "meetingType",
        name: "Tipo de reunion",
      },
      {
        field: "meetingDate",
        name: "Fecha de reunion",
      },
      {
        field: "",
        name: "Estado",
        render: (meeting: MeetingType) => {
          if (meeting.status) {
            if (meeting.meetingDate === moment().format("L")) {
              return (
                <EuiBadge color="success">
                  <Link
                    to={`/join/${meeting.meetingId}`}
                    style={{ color: "black" }}
                  >
                    Unirse
                  </Link>
                </EuiBadge>
              );
            } else if (
              moment(meeting.meetingDate).isBefore(moment().format("L"))
            ) {
              return <EuiBadge color="default">Finalizada</EuiBadge>;
            } else if (moment(meeting.meetingDate).isAfter()) {
              return <EuiBadge color="primary">Upcoming</EuiBadge>;
            }
          } else return <EuiBadge color="danger">Cancelada</EuiBadge>;
        },
      },
      {
        field: "",
        name: "Editar",
        width: "5%",
        render: (meeting: MeetingType) => {
          return (
            <EuiButtonIcon
              aria-label="meeting-edit"
              iconType="indexEdit"
              color="danger"
              display="base"
              isDisabled={
                moment(meeting.meetingDate).isBefore(moment().format("L")) ||
                !meeting.status
              }
              onClick={() => openEditFlyout(meeting)}
            />
          );
        },
      },
      {
        field: "meetingId",
        name: "Copiar link",
        width: "5%",
        render: (meetingId: string) => {
          return (
            <EuiCopy
              textToCopy={`${process.env.REACT_APP_HOST}/join/${meetingId}`}
            >
              {(copy: any) => (
                <EuiButtonIcon
                  iconType="copy"
                  onClick={copy}
                  display="base"
                  aria-label="meeting-copy"
                />
              )}
            </EuiCopy>
          );
        },
      },
    ];
  
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Header />
        <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
          <EuiFlexItem>
            <EuiPanel>
              <EuiBasicTable items={meetings} columns={meetingColumns} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        {showEditFlyout && (
          <EditFlyout closeFlyout={closeEditFlyout} meeting={editMeeting!} />
        )}
      </div>
    );
  }