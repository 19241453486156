import "./home.scss";
import Sidebar from '../../../components/MenuComponents/Sidebar/Sidebar';
import Navbar from '../../../components/MenuComponents/Navbar/Navbar';
import Widget from '../../../components/MenuComponents/Widgets/Widget';
import Featured from '../../../components/MenuComponents/Featured/Featured';
import { Chart } from '../../../components/MenuComponents/Chart/Chart';
import useAuth from '../../../hooks/useAuth';

const Home = () => {
  useAuth();
  return (
    <div className='home'>
      <Sidebar />
      <div className="homeContainer">
        <Navbar/>
        <div className="widgets">
          <Widget type='users'/>
          <Widget type='clients'/>
          <Widget type='earnings'/>
          <Widget type='balance'/>
        </div>
        <div className="charts">
          <Featured/>
          <Chart/>
        </div>
      </div>
    </div>
  )
}

export default Home