import "./new.scss";
import useAuth from '../../../hooks/useAuth';
import Sidebar from '../../../components/MenuComponents/Sidebar/Sidebar';
import Navbar from '../../../components/MenuComponents/Navbar/Navbar';
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useState, useEffect } from "react";
import { addDoc, collection, serverTimestamp, getDocs, getFirestore, query, where, limit} from "firebase/firestore";
import { firebaseDB, storage } from "../../../utils/FirebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {  } from "firebase/firestore";
import { v4 } from 'uuid';

const New = ({ inputs, title, collectionName }) => {
  const [file, setFile] = useState("");
  const [per, setPerc] = useState(null);
  const [data, setData] = useState({});

  useAuth();

  async function emailAlreadyExists(email) {
    const db = getFirestore();
  
    const querySnapshot = await getDocs(query(
      collection(db, "users"),
      where("email", "==", email),
      limit(1)
    ));
    return !querySnapshot.empty
  }

  async function clientIdAlreadyExists(id) {
    const db = getFirestore();
  
    const querySnapshot = await getDocs(query(
      collection(db, "clients"),
      where("id", "==", id),
      limit(1)
    ));
  
    console.log(!querySnapshot.empty)
    return !querySnapshot.empty
  }


  async function serialNumberAlreadyExists(serialNumber) {
    const db = getFirestore();
  
    const querySnapshot = await getDocs(query(
      collection(db, "machines"),
      where("serialNumber", "==", serialNumber),
      limit(1)
    ));
  
    console.log(!querySnapshot.empty)
    return !querySnapshot.empty
  }

  useEffect(() =>{
    const uploadFile = () =>{
      const name = new Date().getTime() + file.name
      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          setPerc(progress);
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    };
    file && uploadFile();
  }, [file]);

  const handleAdd = async (e) =>{
    e.preventDefault()
    try {
      if (collectionName === 'users'){
        if (await emailAlreadyExists(data.email)) {
          console.log('Email does exist. Skipping document addition.');
          // Agregar pop-up
        } else {
          await addDoc(collection(firebaseDB, collectionName), { uid: v4().toString(),
            ...data,  
          });
      
          if (data.accessLevel === '4') {
            await addDoc(collection(firebaseDB, 'technicians'), {
              name: data.name,
              email: data.email,
              phone: data.phone,
              company: data.company
            });
          }
        }
      }

      else if (collectionName === 'machines'){
        if (await serialNumberAlreadyExists(data.serialNumber)){
          // Agregar pop-up
          console.log('Serial Number does exist. Skipping document addition.');
        } else {
          await addDoc(collection(firebaseDB, collectionName), {
            ...data,
            timestamp: serverTimestamp()    
          });
        }
      }

      else if (collectionName === 'clients'){
        if (await clientIdAlreadyExists(data.id)){
          // Agregar pop-up
          console.log('Client ID does exist. Skipping document addition.');
        } else {
          await addDoc(collection(firebaseDB, collectionName), {
            ...data,
            timestamp: serverTimestamp()    
          });
        }
      }

    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  const handleInput = (e) =>{
    const id = e.target.id;
    const value = e.target.value;

    setData({...data, [id]:value})
  };

  return (
    <div className='new'>
      <Sidebar/>
      <div className="newContainer">
        <Navbar/>
        <div className="top">
        <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
          <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleAdd}>
              <div className="formInput">
                <label htmlFor="file">
                  Imagen: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input  autoComplete="off" id={input.id} type={input.type} placeholder={input.placeholder} onChange={handleInput}/>
                </div>
              ))}
              <button  disabled={per !== null && per < 100} type="submit" >Enviar</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default New