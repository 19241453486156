import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiImage } from '@elastic/eui'
import React from 'react'
import Header from "../../components/MeetingsComponents/Header";
import useAuth from '../../hooks/useAuth';
import meeting1 from '../../assets/meeting1.png';
import meeting2 from '../../assets/meeting2.png'
import { useNavigate } from 'react-router';

function CreateMeeting() {
    useAuth();
    const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <Header />
        <EuiFlexGroup
          justifyContent="center"
          alignItems="center"
          style={{ margin: "5vh 10vw" }}
        >
          <EuiFlexItem>
            <EuiCard
              icon={<EuiImage src={meeting1} alt="icon" size="100%" />}
              title={`Crear reunion 1 a 1`}
              description="Crea una reunión personal de una sola persona."
              onClick={() => navigate("/createOneOnOne")}
              paddingSize="xl"
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              icon={<EuiImage src={meeting2} alt="icon" size="100%" />}
              title={`Crear video conferencia`}
              description="Invita multiples personas a una misma reunion."
              onClick={() => navigate("/createVideoConference")}
              paddingSize="xl"
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </>
  )
}

export default CreateMeeting