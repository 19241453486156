import React, { useContext } from 'react';
import './navbar.scss';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { DarkModeContext } from '../../../app/context/darkModeContext';

const Navbar = () => {
  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className='navbar'>
      <div className="wrapper">
        <div className="search">
          <input type="text" placeholder='Busqueda...' />
          <SearchIcon/>
        </div>
        <div className="items">
        <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <DarkModeIcon className='icon' onClick={() => dispatch({ type: "TOGGLE" })}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar