import { getFirestore, collection, getDocs, query, where, limit } from "firebase/firestore";

export const getUserInfo = async (currentUserInfo) => {
  try {
    const db = getFirestore();
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(query(usersRef, where('email', '==', currentUserInfo.email), limit(1)));

    const userData = querySnapshot.docs[0].data();
    return userData;
 
  } catch (error) {
    console.error('Error occurred:', error);
    throw error;
  }
};
