import React from 'react'
import './widget.scss';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BalanceIcon from '@mui/icons-material/Balance';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

const Widget = ({ type }) => {
  let data;

  // temporary

  const amount = 38000;

  switch(type){
    case "users":
      data = {
        title: "USUARIOS",
        isMoney: false,
        link: "Ver todos",
        icon: <PersonOutlinedIcon className='icon' style={{
          color: "#2e31d8",
          backgroundColor: "#aeafe7"
        }}/>,
      };
      break;
    case "clients":
      data = {
        title: "CLIENTES",
        isMoney: false,
        link: "Ver todos",
        icon: <BusinessCenterIcon className='icon'
        style={{
          color: "#C07F00",
          backgroundColor: "#FFD95A"
        }}/>,
      };
      break;
    case "earnings":
      data = {
        title: "INGRESOS",
        isMoney: true,
        link: "Ver ingresos brutos",
        icon: <AttachMoneyIcon className='icon'
        style={{
          color: "#5F8D4E",
          backgroundColor: "#A4BE7B"
        }}/>,
      };
      break;
    case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "Ver ingresos netos",
        icon: <BalanceIcon className='icon'
        style={{
          color: "#2A2F4F",
          backgroundColor: "#917FB3"
        }}/>,
      };
      break;
    default:
      break;
  }
  return (
    <div className="widget">
        <div className="left">
            <span className="title">{ data.title }</span>
            <span className="counter">{data.isMoney && "$"}{amount}</span>
            <span className="link">{data.link}</span>
        </div>
        <div className="right">
            <span className="percentage positive">
              <KeyboardDoubleArrowUpIcon/> 
              10%
            </span>
            {data.icon}
        </div>
    </div>
    
  )
}

export default Widget