import { getFirestore, query, where, limit, getDocs, collection } from 'firebase/firestore';
import { firebaseAuth } from "./FirebaseConfig";

export const getCurrentUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      firebaseAuth.onAuthStateChanged(async (user) => {
        if (user) {
          const db = getFirestore();
          const usersRef = collection(db, 'users');
          const querySnapshot = await getDocs(query(usersRef, where('email', '==', user.email), limit(1)));
    
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            resolve(userData);
          } else {
            console.log('No matching document found.');
            reject('No matching document found.');
          }
        } else {
          console.log('User is not logged in.');
          reject('User is not logged in.');
        }
      });
    } catch (error) {
      console.error('Error occurred:', error);
      reject(error);
    }
  });
};
