import React from 'react';
import "./list.scss";
import Sidebar from '../../../components/MenuComponents/Sidebar/Sidebar';
import Navbar from '../../../components/MenuComponents/Navbar/Navbar';
import DataTable from '../../../components/MenuComponents/Datatable/DataTable';
import useAuth from '../../../hooks/useAuth';

const List = ({ collectionName }) => {
  useAuth();
  return (
    <div className='list'>
      <Sidebar/>
      <div className="listContainer">
        <Navbar/>
        <DataTable collectionName = { collectionName }/>
      </div>
    </div>
  )
}

export default List;