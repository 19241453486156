import React from 'react'
import './featured.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { CircularProgressbar } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

const Featured = () => {
  return (
    <div className='featured'>
        <div className="top">
            <h1 className="title">Ingresos Totales</h1>
            <MoreVertIcon fontSize='small'/>
        </div>
        <div className="bottom">
            <div className="featuredChart">
                <CircularProgressbar value={70} text={'%70'} strokeWidth={5}/>
            </div>
            <p className="title">Ingresos Totales del mes</p>
            <p className="amount" style={{color:'rgb(7, 214, 7)'}}>$21.000</p>
            <p className="desc">Ingresos de suscripcion, pueden faltar acreditarse para el total previsto</p>
            <div className="summary">
              <div className="item">
                <div className="itemTitle">Ingresos</div>
                <div className="itemResult positive">
                  <KeyboardDoubleArrowUpIcon fontSize='small'/>
                  <div className="resultAmount">$21.000</div>
                </div>
              </div>

              <div className="item">
                <div className="itemTitle">Egresos</div>
                <div className="itemResult negative">
                  <KeyboardDoubleArrowDownIcon fontSize='small'/>
                  <div className="resultAmount ">$3.000</div>
                </div>
              </div>

              <div className="item">
                <div className="itemTitle">Balance</div>
                <div className="itemResult positive">
                  <KeyboardDoubleArrowUpIcon fontSize='small'/>
                  <div className="resultAmount">$18.000</div>
                </div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Featured